export const experienceData = [
    {
        id: 1,
        company: 'Aqore Software',
        jobtitle: 'Software Engineer',
        startYear: '2023',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Sherpa Technologies',
        jobtitle: 'Flutter Developer',
        startYear: '2021',
        endYear: '2023'
    },
    {
        id: 3,
        company: 'Chitwan Print House',
        jobtitle: 'Graphic Designer',
        startYear: '2020',
        endYear: '2021'
    },
]