export const educationData = [
    {
        id: 1,
        institution: 'Aroma College',
        address: "Bharatpur, Chitwan, Nepal",
        course: 'Higher Secondary Education (Bio-Science)',
        startYear: '2015',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'National Infotech',
        course: 'Bachelor of Science in Computer Science & Information Technology',
        address: "Birgunj, Nepal",
        startYear: '2018',
        endYear: '2023'
    },
]