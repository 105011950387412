export const aboutData = {
    title: "Who I am",
    description1: "Hey 👋🏻, Myself Mustafa Hawari. I'm a Software Engineer based in Kathmandu, Nepal.",
    description2:
        `
        During the day I work with company and in the evening I  work on freelance projects and utilize the time 
        to built my own products. Right now I'm also trying a hand at machine learning and AI. 
        I love to learn and explore new arenas.
    `,
    description3:
        `
    I have implemented various features such as Google Maps/MapBox, Chat, In App Purchase, FCM, 
    Authentication, Localization, and Local Data Catching to ensure seamless integration into the overall application.
     To manage the application's state, I have used Bloc, RxDart, Provider, GetX, Riverpod, and have customized packages
      and plugins as per the requirements. 
    `,
    description4:
        `
    As a part-time freelancer, I have also developed, managed, and designed mobile 
    applications for multiple clients. My focus is always on helping buyers with the right technologies 
    to grow their business. I have a passion for mobile app development and I am constantly learning 
    and improving my skills. Thank you for visiting my website and please feel free to contact me for any inquiries.
    `,
    image: 2
}